import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/App.css";

function ShippingCalculator() {
  const navigate = useNavigate(); // Use navigate for navigation

  // Define all variables for messages and labels in Spanish
  const alertaValorDeclarado = "El valor declarado supera el límite.";
  const alertaMaxFilas = "No puedes agregar más de 10 filas.";
  const alertaCamposIncompletos = "Por favor completa todos los campos.";
  const alertaSeleccionCourier = "Por favor selecciona un courier.";
  const exitoEnvioEmail = "¡Correo enviado con éxito!";
  const errorEnvioEmail = "Error al enviar el correo.";
  const tuEnvio = "Tu Envío";
  const flete = "Flete";
  const seguro = "Seguro";
  const valorImpuesto = "Impuesto";
  const totalEn = "Total en";
  const regresar = "Regresar";
  const registrarse = "Registrarse";
  const botonWhatsapp = "Contactar por WhatsApp";
  const cotizar = "Cotizar";
  const calcularTarifasEnvio = "Calcular Tarifas de Envío";
  const nombreCompleto = "Nombre Completo";
  const telefono = "Teléfono";
  const correoElectronico = "Correo Electrónico";
  const ciudad = "Ciudad";
  const courierColombia = "Desde USA";
  const courierChina = "Desde China";
  const courierEspana = "Desde España";
  const cajas = "Cajas";
  const peso = "Peso";
  const dimensiones = "Dimensiones";
  const valorDeclarado = "Valor Declarado";
  const calcularTarifas = "Calcular Tarifas";

  const [rows, setRows] = useState([{ cajas: 1, pesoTotal: 1, valorDeclarado: 0 }]);
  const [showCotizacion, setShowCotizacion] = useState(false);
  const [calculated, setCalculated] = useState(false);
  const [courier, setCourier] = useState('');
  const [shippingCost, setShippingCost] = useState(0);
  const [selectedCourier, setSelectedCourier] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    telefono: '',
    email: '',
    ciudad: '',
  });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(true);

  useEffect(() => {
    const cost = calculateShippingCost(selectedCourier);
    setShippingCost(cost);
  }, [selectedCourier, rows]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const firstInputElement = document.querySelector('input');
    if (firstInputElement) {
      firstInputElement.focus();
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const calculateShippingCost = (courier) => {
    switch (courier) {
      case 'Colombia':
        return calculateShippingCostColombia();
      case 'China':
        return calculateShippingCostChina();
      case 'Spain':
        return calculateShippingCostSpain();
      default:
        return 0;
    }
  };

  const calculateShippingCostColombia = () => {
    const precioPrimeralbColombia = 12;
    const precioPorLibraAdicionalColombia = 1.8;

    const totalCostoEnvioColombia = rows.reduce((total, row) => {
      const totalWeight = row.pesoTotal * row.cajas;
      let shippingCostColombia = 0;

      if (totalWeight <= 1) {
        shippingCostColombia = totalWeight * precioPrimeralbColombia;
      } else {
        shippingCostColombia = (precioPrimeralbColombia + (totalWeight - 1) * precioPorLibraAdicionalColombia);
      }

      return total + shippingCostColombia;
    }, 0);

    return totalCostoEnvioColombia;
  };

  const calculateShippingCostChina = () => {
    const precioPrimeralb = 15;
    const precioPorLibraAdicional = 7.5;

    const totalWeight = rows.reduce((total, row) => {
      return total + (row.pesoTotal * row.cajas);
    }, 0);

    let shippingCost = 0;
    if (totalWeight <= 1) {
      shippingCost = precioPrimeralb;
    } else {
      shippingCost = precioPrimeralb + ((totalWeight - 1) * precioPorLibraAdicional);
    }

    return shippingCost;
  };

  const calculateShippingCostSpain = () => {
    const precioPrimeralbRange1to4 = 30;
    const precioPorLibraAdicionalSpain = 5.5;

    const totalCostoEnvioSpain = rows.reduce((total, row) => {
      const totalWeight = row.pesoTotal * row.cajas;
      let shippingCostSpain = 0;

      if (
        !isNaN(row.length) && row.length > 0 &&
        !isNaN(row.width) && row.width > 0 &&
        !isNaN(row.height) && row.height > 0
      ) {
        const volumeInCm3 = row.length * row.width * row.height;
        const volumeInLb = volumeInCm3 / 6000 / 2;
        const weightForShipping = Math.max(totalWeight, volumeInLb);

        if (weightForShipping <= 4) {
          shippingCostSpain = precioPrimeralbRange1to4;
        } else {
          shippingCostSpain = precioPrimeralbRange1to4 + ((weightForShipping - 4) * precioPorLibraAdicionalSpain);
        }
      } else {
        console.log("Dimensiones incompletas para calcular el costo de envío a España.");
      }

      return total + shippingCostSpain;
    }, 0);

    return totalCostoEnvioSpain;
  };

  const calculateInsuranceFee = () => {
    const totalInsuranceFee = rows.reduce((total, row) => {
      let declaredValueFee = row.valorDeclarado * 0.02 * row.cajas;

      if (declaredValueFee <= 2) {
        declaredValueFee = 2;
      }

      return total + declaredValueFee;
    }, 0);

    return totalInsuranceFee;
  };

  const calculateTax = () => {
    const totalTax = rows.reduce((total, row) => {
      let tax = 0;
      if (selectedCourier === 'Colombia') {
        if (row.valorDeclarado > 200) {
          tax = row.valorDeclarado * 0.39 * row.cajas;
        }
      } else {
        tax = row.valorDeclarado * 0.39 * row.cajas;
      }
      return total + tax;
    }, 0);

    return totalTax;
  };

  const calculateTotalCost = () => {
    const totalShippingCost = getShippingCost();
    const totalInsurance = calculateInsuranceFee();
    const totalTaxAmount = calculateTax();
    const totalCost = totalShippingCost + totalInsurance + totalTaxAmount;

    return totalCost;
  };

  const handlePesoTotalChange = (event, index) => {
    event.preventDefault();
    const newRows = [...rows];
    const newValue = parseFloat(event.target.value);

    if (isNaN(newValue) || newValue < 0 || newValue > 110) {
      newRows[index].pesoTotal = '';
    } else {
      newRows[index].pesoTotal = newValue;
    }

    setRows(newRows);
  };

  const handleCantidadCajas = (event, index) => {
    event.preventDefault();
    const newRows = [...rows];
    let newValue = parseInt(event.target.value);

    if (newValue < 1 || isNaN(newValue) || newValue === undefined) {
      newValue = 1;
    }

    newRows[index].cajas = newValue;
    setRows(newRows);
  };

  const handleDimensionChange = (event, dimension, index) => {
    event.preventDefault();
    const newRows = [...rows];
    const newValue = parseInt(event.target.value);

    newRows[index][dimension] = newValue < 0 ? 0 : newValue;

    setRows(newRows);
  };

  const handleValorDeclaradoChange = (event, index) => {
    event.preventDefault();
    const newRows = [...rows];
    let newValue = parseInt(event.target.value);

    let sanitizedValue = newValue < 0 ? 0 : newValue;

    if (isNaN(newValue) || event.target.value.trim() === '') {
      newValue = '';
    } else if (sanitizedValue > 2000) {
      sanitizedValue = 2000;
      console.log(alertaValorDeclarado);
    }

    newRows[index].valorDeclarado = sanitizedValue.toString();
    setRows(newRows);
  };

  const handleAddRow = () => {
    if (rows.length >= 10) {
      console.log(alertaMaxFilas);
      return;
    }

    setRows([...rows, { cajas: 1, pesoTotal: 1, valorDeclarado: 1 }]);
  };

  const handleDeleteRow = (index) => {
    if (rows.length === 1) {
      return;
    }
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  };

  const handleCalcularTarifasClick = (event) => {
    event.preventDefault();

    const anyInvalid = rows.some(row =>
      isNaN(row.cajas) || row.cajas === '' || row.cajas === 0 ||
      isNaN(row.pesoTotal) || row.pesoTotal === '' || row.pesoTotal === 0 ||
      isNaN(row.valorDeclarado) || row.valorDeclarado === '' || row.valorDeclarado === 0 ||
      (selectedCourier === 'Spain' && (isNaN(row.length) || row.length <= 0 || isNaN(row.width) || row.width <= 0 || isNaN(row.height) || row.height <= 0))
    );

    const formFieldsEmpty = !formData.name || !formData.telefono || !formData.email || !formData.ciudad;

    if (anyInvalid || formFieldsEmpty || selectedCourier === '') {
      setShowModal(true);
      return;
    }

    // Start loading
    setLoading(true);

    setTimeout(() => {
      setShowCotizacion(true);
      setCalculated(true);
      calculateTotalCost();
      handleSubmit();
      setLoading(false);
    }, 2000);
  };

  const handleCourierSelection = (courier) => {
    setSelectedCourier(courier); // Only set the selected courier
  };

  const handleContinue = () => {
    const formFieldsEmpty = !formData.name || !formData.telefono || !formData.email || !formData.ciudad;
    if (formFieldsEmpty || selectedCourier === '') {
      setShowModal(true);
      return;
    }
    setShowContactInfo(false); // Proceed to shipping table after validation
  };

  const handleBackToContactInfo = () => {
    setSelectedCourier(''); // Reset the selected courier
    setShowContactInfo(true); // Show contact info when user clicks "Regresar"
  };

  const getShippingCost = () => {
    switch (selectedCourier) {
      case 'Colombia':
        return calculateShippingCostColombia();
      case 'China':
        return calculateShippingCostChina();
      case 'Spain':
        return calculateShippingCostSpain();
      default:
        return 0;
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('https://cotizadorbackend.globuscargo.us/send-email', { // Corrected endpoint
        ...formData,
        rows,
      });
      if (response.status === 200) {
        console.log(exitoEnvioEmail);
      } else {
        console.log(errorEnvioEmail);
      }
    } catch (error) {
      console.error(error);
      console.log(errorEnvioEmail);
    }
  };

  const LoadingSpinner = () => (
    <div className="loading-container">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );

  const cotizacionSection = showCotizacion && (
    <div className='container cotizacion py-5'>
      <div className="quote-header text-center mb-4">{tuEnvio}</div>
      <div className="quote-details">
        <div className="quote-item d-flex justify-content-between mb-3">
          <div className="title-rate">{flete}:</div>
          <div className="value-rate">${calculated ? getShippingCost().toFixed(2) : '0.00'}</div>
        </div>
        <div className="quote-item d-flex justify-content-between mb-3">
          <div className="title-rate">{seguro}:</div>
          <div className="value-rate">${calculated ? calculateInsuranceFee().toFixed(2) : '0.00'}</div>
        </div>
        <div className="quote-item d-flex justify-content-between mb-3">
          <div className="title-rate">{valorImpuesto}:</div>
          <div className="value-rate">${calculated ? calculateTax().toFixed(2) : '0.00'}</div>
        </div>
        <hr />
        <div className="quote-total d-flex justify-content-between">
          <div className="title-rate total">{totalEn} {selectedCourier === 'Spain' ? 'EUR' : 'USD'}:</div>
          <div className="total-value">{calculated ? calculateTotalCost().toFixed(2) : '0.00'}</div>
        </div>
      </div>
      <div className='d-flex justify-content-between mt-4'>
        <button className="btn btn-secondary" onClick={() => setShowCotizacion(false)}>{regresar}</button>
        <a href="https://globuscargo.us/#/sign-up?a=90408c7a-fb1e-4b68-b000-8b02fe47ddf9" className="btn btn-primary">{registrarse}</a>
        <a href="https://api.whatsapp.com/send/?phone=7868991636&text=asd" className="btn btn-success">
          <FaWhatsapp style={{ marginRight: "8px" }} />
          {botonWhatsapp}
        </a>
      </div>
    </div>
  );

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {!showCotizacion && (
            <div className='container py-5'>
              <div className='row justify-content-center'>
                <div className='col-lg-8 col-md-8'>
                  <div className="form-holder p-5 bg-white shadow">
                    <div className="form-content">
                      <div className="form-items">

                        {/* Contact Information and Courier Selection for Mobile */}
                        <div className={`contact-info ${showContactInfo ? '' : 'inactive'}`}>
                          <h3 className="text-center mb-4">{cotizar}</h3>
                          <form className="requires-validation" noValidate>
                            {/* Contact Inputs */}
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="name" className="d-md-none">{nombreCompleto}</label>
                                <input 
                                  className="form-control" 
                                  type="text" 
                                  name="name" 
                                  id="name"
                                  placeholder={nombreCompleto} 
                                  value={formData.name || ''}  // Ensure it's controlled
                                  required 
                                  onChange={handleInputChange} 
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="telefono" className="d-md-none">{telefono}</label>
                                <input 
                                  className="form-control" 
                                  type="text" 
                                  name="telefono" 
                                  id="telefono"
                                  placeholder={telefono} 
                                  value={formData.telefono || ''}  // Ensure it's controlled
                                  required 
                                  onChange={handleInputChange} 
                                />
                              </div>
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label htmlFor="email" className="d-md-none">{correoElectronico}</label>
                                <input 
                                  className="form-control" 
                                  type="email" 
                                  name="email" 
                                  id="email"
                                  placeholder={correoElectronico} 
                                  value={formData.email || ''}  // Ensure it's controlled
                                  required 
                                  onChange={handleInputChange} 
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="ciudad" className="d-md-none">{ciudad}</label>
                                <input 
                                  className="form-control" 
                                  type="text" 
                                  name="ciudad" 
                                  id="ciudad"
                                  placeholder={ciudad} 
                                  value={formData.ciudad || ''}  // Ensure it's controlled
                                  required 
                                  onChange={handleInputChange} 
                                />
                              </div>
                            </div>
                          
                            {/* Courier Buttons */}
                            <div className="courier-buttons">
                              <button 
                                type="button" 
                                className={`btn ${selectedCourier === 'Colombia' ? 'btn-primary active' : 'btn-outline-primary'}`} 
                                onClick={() => handleCourierSelection("Colombia")}
                              >
                                {courierColombia}
                              </button>
                              <button 
                                type="button" 
                                className={`btn ${selectedCourier === 'China' ? 'btn-primary active' : 'btn-outline-primary'}`} 
                                onClick={() => handleCourierSelection("China")}
                              >
                                {courierChina}
                              </button>
                              <button 
                                type="button" 
                                className={`btn ${selectedCourier === 'Spain' ? 'btn-primary active' : 'btn-outline-primary'}`} 
                                onClick={() => handleCourierSelection("Spain")}
                              >
                                {courierEspana}
                              </button>
                            </div>

                            {/* Continue Button for Mobile */}
                            {window.innerWidth <= 768 && (
                              <div className="d-flex justify-content-center mt-3">
                                <button 
                                  type="button" 
                                  className="btn btn-primary" 
                                  onClick={handleContinue}
                                >
                                  Continuar
                                </button>
                              </div>
                            )}
                          </form>
                        </div>

                        {/* Shipping Table */}
                        <div className={`shipping-table ${!showContactInfo ? 'active' : ''}`}>
                          {/* "Regresar" Button for Mobile to go back to contact info */}
                          <div className="d-flex justify-content-end mb-3">
                            {!showContactInfo && (
                              <button className="btn btn-secondary" onClick={handleBackToContactInfo}>{regresar}</button>
                            )}
                          </div>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              {/* Headers for larger screens */}
                              <thead className="thead-light d-none d-md-table-header-group">
                                <tr>
                                  <th>{cajas}</th>
                                  <th>{peso}</th>
                                  <th>{dimensiones}</th>
                                  <th>{valorDeclarado}</th>
                                  <th></th>
                                </tr>
                              </thead>

                              {/* Table body for all screens */}
                              <tbody>
                                {rows.map((row, index) => (
                                  <tr key={index}>
                                    <td data-label={cajas}>
                                      <input
                                        type="number"
                                        name="cajas"
                                        value={row.cajas || ''}
                                        onChange={(event) => handleCantidadCajas(event, index)}
                                        disabled={showCotizacion}
                                        className="form-control"
                                        required
                                      />
                                    </td>
                                    <td data-label={peso}>
                                      <input
                                        type="number"
                                        name="pesoTotal"
                                        value={row.pesoTotal || ''}
                                        onChange={(event) => handlePesoTotalChange(event, index)}
                                        disabled={showCotizacion}
                                        className="form-control"
                                        required
                                      />
                                    </td>
                                    <td data-label={dimensiones}>
                                      <div className="d-flex justify-content-between">
                                        <input
                                          type="number"
                                          name="length"
                                          value={row.length || ''}
                                          onChange={(event) => handleDimensionChange(event, 'length', index)}
                                          disabled={showCotizacion}
                                          className="form-control"
                                          required
                                        />
                                        <input
                                          type="number"
                                          name="width"
                                          value={row.width || ''}
                                          onChange={(event) => handleDimensionChange(event, 'width', index)}
                                          disabled={showCotizacion}
                                          className="form-control mx-2"
                                          required
                                        />
                                        <input
                                          type="number"
                                          name="height"
                                          value={row.height || ''}
                                          onChange={(event) => handleDimensionChange(event, 'height', index)}
                                          disabled={showCotizacion}
                                          className="form-control"
                                          required
                                        />
                                      </div>
                                    </td>
                                    <td data-label={valorDeclarado}>
                                      <input
                                        type="number"
                                        name="valorDeclarado"
                                        value={row.valorDeclarado || ''}
                                        onChange={(event) => handleValorDeclaradoChange(event, index)}
                                        disabled={showCotizacion}
                                        className="form-control"
                                        min="0"
                                        required
                                      />
                                    </td>
                                    <td className="d-flex align-items-center">
                                      <button
                                        type="button"
                                        className="btn btn-secondary btn-sm mr-2"
                                        onClick={handleAddRow}
                                        disabled={showCotizacion}
                                      >
                                        +
                                      </button>
                                      <button
                                        className="btn btn-danger btn-sm"
                                        type="button"
                                        onClick={() => handleDeleteRow(index)}
                                        disabled={showCotizacion}
                                      >
                                        X
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          <div className="d-flex justify-content-between">
                            <Link to="https://globuscargo.com/" className="btn btn-secondary" onClick={handleBackToContactInfo}>{regresar}</Link>
                            <button id="submit" type="submit" className="btn btn-primary" onClick={(event) => handleCalcularTarifasClick(event)}>{calcularTarifas}</button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showCotizacion && cotizacionSection}
          <hr />
        </>
      )}

      {/* Modal for displaying validation errors */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered className="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>Advertencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>{alertaCamposIncompletos}</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ShippingCalculator;
